<template lang="pug">
  div(style="max-width: 720px; margin: auto")
    h1 {{$t('settings.title')}}
    .py-2

    h3 {{$t('settings.appearance')}}

    Divider
    v-list.py-0(dense)
      v-list-item
        v-list-item-content
          v-list-item-title {{$t('settings.cardIconSimple')}}

        v-list-item-action
          v-switch(:input-value="$settings.get('cardIconSimple')",
            @change="x => $settings.set('cardIconSimple', x)")

      Divider(inset=16)
      v-list-item(style="overflow: hidden")
        v-list-item-content(style="overflow: visible")
          v-list-item-title {{$t('settings.cardIconAfterTrainingAuto')}}
          v-list-item-subtitle {{$t('settings.costing')}}
          .mx-n2.pb-1
            v-slider(dense, hide-details, min=0, max=6, step=1,
              :value="$settings.get('cardIconAfterTrainingAuto')", 
              @change="x => $settings.set('cardIconAfterTrainingAuto', x)",
              tick-size=1, :tick-labels="[$t('settings.cardIconAfterTrainingOff'), 0, 2, 3, 4, 0, $t('settings.cardIconAfterTrainingAlways')]")

      Divider(inset=16)
      v-list-item
        v-list-item-content
          v-list-item-title {{$t('settings.footerSafeArea')}}
        
        v-list-item-action
          v-switch(:input-value="$settings.get('footerSafeArea')",
            @change="x => $settings.set('footerSafeArea', x)")

    Divider
    .py-2
    
    h3 {{$t('settings.functions')}}

    Divider
    v-list.py-0(dense)
      v-list-item(style="overflow: hidden")
        v-list-item-content(style="overflow: visible")
          v-list-item-title {{$t('settings.recommendingFullPerfectCoef')}}
          .mx-n2.pb-1
            v-slider(dense, hide-details, min=0, max=1, step=0.1,
              :value="$settings.get('recommendingFullPerfectCoef')",
              @change="x => $settings.set('recommendingFullPerfectCoef', x)"
              tick-size=1, :tick-labels="[$t('settings.recommendingFullPerfectCoefLow'), 0, 0, 0, 0, 0, 0, 0, 0, 0, $t('settings.recommendingFullPerfectCoefHigh')]")

      Divider(inset=16)
      v-list-item
        v-list-item-content
          v-list-item-title {{$t('settings.rankingAutoLoad')}}
          v-list-item-subtitle {{$t('settings.costing')}}
        
        v-list-item-action
          v-switch(:input-value="$settings.get('rankingAutoLoad')",
            @change="x => $settings.set('rankingAutoLoad', x)")

    Divider
    .py-2

    h3 {{$t('settings.reload')}}

    Divider
    v-list.py-0(dense)
      v-list-item(@click="$root.$emit('reloadDatabase')")
        v-list-item-content
          v-list-item-title {{$t('settings.reloadDatabase')}}

      Divider(inset=16)
      v-list-item(@click="reloadApplication()")
        v-list-item-content
          v-list-item-title {{$t('settings.reloadApplication')}}

    Divider
    .py-2

</template>

<script>
import { unregister } from 'register-service-worker';

export default {
  name: 'Settings',

  methods: {
    reloadApplication() {
      unregister();
      location.reload();
    }
  }
};
</script>
